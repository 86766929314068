.container {
  min-height: 100svh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  // background-image: url("../../../../public/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header {
  flex-grow: 0;
}

.body {
  flex-grow: 1;
}
