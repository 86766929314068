// @tailwind base;
@tailwind components;
@tailwind utilities;
@import "src/lib/scss/_global.scss";
@import "@fontsource/plus-jakarta-sans";
@import "@fontsource/plus-jakarta-sans/400.css";
@import "@fontsource/plus-jakarta-sans/400-italic.css";

body {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e293b;
}

* {
  font-family: "Plus Jakarta Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-white-box {
  background-color: #fff;
  border-radius: 24px;
  margin-bottom: 74px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  width: 90%;
  max-width: 515px;

  @media (min-width: 640px) {
    width: 80%;
  }
}

#logo {
  min-height: 105px;
  padding: 0 !important;
}
.ant-page-header {
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
  padding-block-end: 32px !important;
}

.ant-drawer-content-wrapper aside.ant-layout-sider .ant-layout-sider-children {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-inline: 8px;
  padding-block: 0;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  padding: 0 !important;
  background-color: $primary-sidebar-color;
}

.ant-drawer-content-wrapper
  aside.ant-layout-sider
  .ant-layout-sider-children
  .ant-pro-sider-logo {
  border-block-end: none !important;
  padding-top: 25px;
  padding-left: 10px;
}
.ant-drawer-content-wrapper
  aside.ant-layout-sider
  .ant-layout-sider-children
  .ant-pro-sider-footer {
  margin-bottom: 20px;
}

.ant-table-tbody > tr:nth-child(odd) td {
  background-color: #fff !important;
}

.ant-table-tbody > tr:nth-child(even) td {
  background-color: rgba(203, 213, 225, 0.3) !important;
}

.ant-table-tbody tr:hover td {
  background-color: rgba(203, 213, 225, 0.1) !important;
}

.ant-menu-title-content * {
  color: #ffffff;
}
.ant-pro-sider-logo {
  border: none;
}
.ant-drawer-content-wrapper .ant-pro-page-container-children-container {
  padding-block-end: 0;
}

.ant-drawer-content-wrapper .ant-layout-sider {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.ant-drawer-content-wrapper .ant-menu-item {
  width: 100%;
  margin: 4px 0;
  color: $primary-sidebar-text-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ant-drawer-content-wrapper .ant-menu-submenu > div.ant-menu-submenu-title {
  width: 100%;
  margin: 4px 0;
  border-radius: 0;
  color: $primary-sidebar-text-color;
}

.ant-drawer-content-wrapper .ant-menu-submenu .ant-menu {
  padding: 0;
}

.ant-drawer-content-wrapper .ant-menu-submenu .ant-menu li {
  padding-left: 38px !important;
}

.ant-drawer-content-wrapper
  .ant-layout-sider-children
  > div:first-child::-webkit-scrollbar {
  display: none;
}

.ant-drawer-content-wrapper .ant-menu .ant-menu-item-active {
  background: transparent !important;
  color: $primary-color !important;
  border-radius: 0 !important;
}

.ant-drawer-content-wrapper .ant-menu .ant-menu-item-selected {
  background: $primary-color !important;
  border-right: 3px solid $primary-color;
  color: #fff !important;
  border-radius: 8px !important;
}

.ant-drawer-content-wrapper
  .ant-menu-submenu.ant-menu-submenu-open
  > div.ant-menu-submenu-title {
  color: $primary-color !important;
}

input:-internal-autofill-selected {
  background: white;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
}

.ant-message-notice-error > .ant-message-notice-content {
  background: #ffe5e5 !important;
  color: #ff3b3b !important;
}

main {
  &.ant-layout-content {
    height: calc(100svh - 56px);
    overflow: auto;
    padding: 0 !important;
    .ant-page-header {
      padding: 8px 16px;
      padding-block-end: 8px !important;
      box-shadow: none;
      .ant-page-header-heading {
        padding-block-start: 0px;
        .ant-page-header-heading-left {
          margin-block: 0px;
          .ant-page-header-heading-title {
            font-weight: 500;
            line-height: 28px;
          }
        }
      }
    }
    .ant-pro-page-container-children-container {
      padding-inline: 16px;
      padding-block-end: 16px;
    }
  }
  .ant-pro-grid-content {
    height: calc(100% - 50px);
  }

  .ant-pro-grid-content-children,
  .ant-pro-page-container-children-container {
    height: 100%;
  }
}

.ant-select-item-option-content {
  .more-info {
    white-space: normal;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.border-default {
  border: 1px solid #eff2f6;
}
.ant-input-number-handler-wrap {
  display: none !important;
}

.date-picker-separate-line {
  width: 1px;
  height: 22px;
  background-color: #ebeef5;
}

.ant-popover-inner {
  padding: 12px !important;
  .custom-popover {
    > div {
      border-bottom: 1px solid #e1e6ea;
    }
  }
}
.hidden-on-screen {
  display: none;
}

@media print {
  .hidden-on-screen {
    display: block;
    padding: 16px 24px;
  }
  .ant-table-body {
    overflow: hidden !important;
    max-height: 100% !important;
  }
  .invoice-delete,
  .hide-on-print {
    display: none;
  }
}
.ant-form-item-explain-error {
  font-weight: normal !important;
}
.ant-tabs-tab-icon {
  margin-inline-end: 8px !important;
}
.ant-upload-select {
  width: 180px !important;
  height: 180px !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 0px 6px 0px #00000033;
  padding: 3px;
  .ant-upload {
    border-radius: 9999px;
    background-color: #00000010;
  }
}

.ant-tabs-nav {
  &::before {
    border-bottom: 1px solid transparent !important;
  }
  .ant-tabs-ink-bar-animated {
    background: #334155 !important;
  }
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #64748b;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #334155 !important;
      font-weight: 600 !important;
    }
  }
}

.ant-drawer-body {
  padding: 16px !important;
  @media (max-width: 1280px) {
    padding: 0 !important;
  }
}
// .ant-form-item
//   .ant-form-item-label
//   > label.ant-form-item-required:not(
//     .ant-form-item-required-mark-optional
//   )::after {
//   display: inline-block;
//   margin-inline-end: 4px;
//   color: #ff4d4f;
//   font-size: 14px;
//   font-family: SimSun, sans-serif;
//   line-height: 1;
//   content: "*";
//   visibility: visible;
// }
.ant-tabs-nav-more {
  display: none;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #cbd5e1 !important;
}

.ant-radio-button-wrapper-checked {
  background-color: #cbd5e1 !important;
  color: #334155 !important;
  border-color: #cbd5e1 !important;
  font-weight: 600;
}

.ant-btn-default:disabled {
  background-color: #f5f5f5;
}
.ant-drawer-body {
  padding-top: 0 !important;
}
.ant-space-item {
  width: 100%;
}
.ant-form-item-label {
  > label {
    &::after {
      display: none;
    }
  }
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: none;
}

.ant-select-selector {
  .more-info {
    display: none;
  }
}

.ant-picker {
  height: 32px;
  .ant-picker-suffix {
    color: $gray;
  }
}

.ant-collapse {
  border: none;
  background-color: transparent !important;
  .ant-collapse-item {
    border: 1px solid #cbd5e1;
    margin-bottom: 8px;
  }
}
