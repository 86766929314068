.container {
  display: flex;
  flex-direction: column;
}

.customCollapsedButton {
  border: none;
  width: min-content;
  height: min-content;
  background: transparent;
  box-shadow: none;
  padding: 0;
  &:focus {
    outline: none;
  }
  transition: all 0.5s;
}

.copyrightContent {
  color: #8e8e93;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
