@import "src/lib/scss/_global.scss";

.proLayoutCustomContainer {
  :global {
    .ant-pro-sider.ant-pro-sider-fixed-mix {
      height: 100vh;
      inset-block-start: 0;
      z-index: 101;
    }
    .ant-layout-sider {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      .ant-layout-sider-children {
        background-color: $primary-sidebar-color;
        .ant-menu-item {
          color: $primary-sidebar-text-color;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          height: 40px;
        }
      }
    }
    .ant-menu-submenu > div.ant-menu-submenu-title {
      color: $primary-sidebar-text-color;
    }
    .ant-menu .ant-menu-item-active {
      background: transparent !important;
      color: $primary-color !important;
      border-radius: 0 !important;
    }
    .ant-menu .ant-menu-item-selected {
      background: $primary-color-bold !important;
      border-right: 3px solid $primary-color;
      color: #fff !important;
      border-radius: 8px !important;
    }

    .ant-pro-layout-container {
      background: #fff !important;
    }

    .ant-pro-layout-container
      .ant-layout-header.ant-pro-layout-header-fixed-header {
      @media screen and (max-width: 767px) {
        width: -webkit-fill-available !important;
      }
    }

    .ant-layout-sider-collapsed .ant-pro-sider-logo-collapsed {
      p {
        display: none;
      }
    }
    .ant-layout-sider-collapsed {
      .ant-pro-base-menu-vertical-item-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .ant-layout-sider-children > div:nth-child(2) {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .ant-pro-layout-container .ant-layout-header #custom-header {
      display: block;
      @media screen and (max-width: $md) {
        display: none;
      }
    }
    .ant-pro-layout-container .ant-layout-header .ant-pro-global-header {
      .ant-pro-global-header-logo + div {
        width: calc(100% - 32px);
      }
    }

    .ant-pro-sider-collapsed-button {
      inset-block-start: 0;
      top: 0;
      right: 0;
      background: transparent;
      box-shadow: none;
    }

    .ant-pro-layout-container .ant-pro-global-header-header-actions {
      border-left: 2px solid #00b3ff;
    }
    .ant-pro-page-container {
      background: #fff !important;
      height: 100%;
    }
  }
}

.isCollapseSideBar {
  :global {
    .ant-pro-layout-container
      .ant-layout-header.ant-pro-layout-header-fixed-header {
      width: calc(100vw - 64px);
    }
  }
}

.isNotCollapseSideBar {
  :global {
    .ant-pro-layout-container
      .ant-layout-header.ant-pro-layout-header-fixed-header {
      width: calc(100vw - 215px);
    }
  }
}

.commonHeaderLayout {
  width: 100%;
  height: 100px;
}

.customCollapsedButton {
  border: none;
  width: min-content;
  height: min-content;
  background: transparent;
  position: absolute;
  top: 5px;
  right: 10px;
  box-shadow: none;
  padding: 0;
  &:focus {
    outline: none;
  }
  transition: all 0.5s;
}

.buttonActionContainer {
  border: none;
}

.rotateCollapsedButton {
  transform: rotate(180deg);
}
.mainLogo {
  img {
    // height: 40px !important;
    color: white;
  }
  @media screen and (max-width: $md) {
    display: none;
  }
}
