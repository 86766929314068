$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

//color for background and border
$primary-color: #0074f6;
$primary-color-bold: #2563eb;
$secondary-color: #e6f7ff;
$silver-1: #fafafa;
$primary-sidebar-color: #3b82f6;

//color for text
$primary-text-color: #141414;
$primary-title-text-color: #141414;
$secondary-text-color: #515a6e;
$primary-sidebar-text-color: #dcdfe6;

$gray: #606266;
$gray1: #262626;
$gray2: #606266;
$gray3: #ebeef5;
$gray4: #f6f8f9;

$yellow1: #fbb435;
$redDanger: #ff5c5c;

$border-radius: 8px;
